import React from 'react';
import ContentLoader from 'react-content-loader';
import { nafColor } from '@nafcore/theme';
import styled from 'styled-components';

export const NewsCardLoader = () => (
  <Wrapper>
    <ContentLoader
      speed={2}
      width={476}
      height={250}
      viewBox="0 0 450 250"
      backgroundColor={nafColor.neutral.neutral1}
      foregroundColor={nafColor.neutral.neutral2}
    >
      <rect x="0" y="11" rx="3" ry="3" width="330" height="154" />
      <rect x="0" y="177" rx="3" ry="3" width="325" height="20" />
      <rect x="0" y="208" rx="3" ry="3" width="330" height="12" />
      <rect x="0" y="228" rx="3" ry="3" width="325" height="11" />
    </ContentLoader>
  </Wrapper>
);

const Wrapper = styled.div`
  height: auto;
  width: 100%;
`;
